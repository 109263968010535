import axios from 'axios' //引入
import tokenTool from '@/utils/token.js'
import router from '@/router/index.js'
import countDown from '@/utils/modal.js'
import bus from '@/utils/bus.js'



// let baseURL = 'http://192.168.0.1:9090'
let baseURL = ''

//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
// if(process.env.NODE_ENV=="development"){
//   baseURL=''
// }else{
//   baseURL=''
// }

let config = {
  baseURL: baseURL,
  timeout: 30 * 1000 //设置最大请求时间
}
const _axios = axios.create(config)
_axios.defaults.timeout = 30000;
/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
  config => {
    let token = tokenTool.getToken()
    if (token) {
      //如果有需要在这里开启请求时的loading动画效果
      config.headers.Authorization = token; //添加token,需要结合自己的实际情况添加，
    }
    return config;
  },
  err => Promise.reject(err)
);

/* 请求之后的操作 */
_axios.interceptors.response.use(
  res => {
    //在这里关闭请求时的loading动画效果
    //这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写
    if (res.data.status === 403) {
      window.antd.Modal.info({
        title: '警告',
        content: `你没有当前操作的权限！`,
      })
      router.push('/')
    }
    if (res.data.status === 401) {
      tokenErr()
    }
    if (res.data.code === 400) {
      Toast("请求网络失败")
    }
    if (res.data.code === 404) {
      Toast("请求网络失败")
    }
    return res;
  },
  err => {
    if (err) {
      //在这里关闭请求时的loading动画效果
      Toast("请求网络失败")
    }
    return Promise.reject(err);
  }
);

//封装post,get方法
const http = {
  get(url = '', params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        params,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        method: 'GET'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  post(url = '', params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        method: 'POST'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  upload(url = '', params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 30 * 1000,
        method: 'POST'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  getFile(url = '', params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        },
        method: 'POST'
      }).then(res => {
        const resData = res.data
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
          if (resData.type === 'application/json') {
            const jsonData = JSON.parse(fileReader.result) // 说明是普通对象数据，后台转换失败
            // 后台信息
            console.log('jsonData:', jsonData)
            if (jsonData.status === 401) {
              tokenErr()
            }
          } else {
            // 下载文件
            downloadFile(resData, params)
          }
        }
        fileReader.readAsText(resData)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
let tokenErr = () => {
  countDown({
    title: '消息',
    content: '未登录或登录过期。',
    expire: 5,
    next: () => {
      tokenTool.clearToken()
      bus.$emit('Data', '123')
    }
  })
}

let Toast = (mes) => {
  window.antd.message.info(mes)
}




function downloadFile(res, params) {
  let blob = new Blob([res.data], {
    type: `application/force-download`, //word文档为msword,pdf文档为pdf
  })
  // 通过URL.createObjectURL生成文件路径          
  let url = window.URL.createObjectURL(blob)
  // 创建a标签
  let ele = document.createElement("a")
  ele.style.display = 'none'
  // 设置href属性为文件路径，download属性可以设置文件名称
  ele.href = url
  ele.download = params.fileName + '.pdf'
  // 将a标签添加到页面并模拟点击          
  document.querySelectorAll("body")[0].appendChild(ele)
  ele.click()
  // 移除a标签
  ele.remove()
}


export default http