const zh = {
    pageHeader: {
        pageHeader: {
            mobleHeader: {
                index: '首页',
                product: '产品',
                product_v: 'RiVAI V',
                product_v7: 'RiVAI V7',
                product_v9: 'RiVAI V9+',
                product_r: 'RiVAI R',
                product_p: 'RiVAI P',
                product_p600: 'RiVAI P600',
                product_e: 'RiVAI E',
                aboutRivai: '关于睿思',
                joinRivai: '加入睿思',
                ideDoc: 'Studio'
            }
        }
    },
    pageFooter: {
        slogan: '睿思芯科是一家基于 RISC-V 开源技术，为中高端市场提供核心处理器的芯片公司。',
        copyRight: 'Copyright © 2018 - 2023 睿思芯科 Inc. 保留所有权利。',
        number1: '粤 ICP 备 18130670 号-1',
        number2: '粤公网安备44030502003396号',
        btnList: {
            product: {
                title: '产品'
            },
            aboutRivai: {
                title: '关于睿思',
                introduce: "公司简介",
                value: "企业价值观",
                devProgress: "发展历程",
                team: "创始人寄语",
                active: "新闻动态",
                contact: "联系我们"
            },
            joinRivai: {
                title: '加入睿思',
                recruit: '社会招聘'
            }
        }
    },
    bodyOverview: {
        bodyOverview: {
            more: '了解更多',
            joinUs: '加入我们',
            aboutUs: '关于我们',
            aboutUsDes: '睿思芯科创立于 2018 年，是本土团队中唯一具有 RISC-V 发起人背景、具备 RISC-V 芯片领域的深厚学识和卓越研发实力的团队',
            newsTitle: '新闻资讯',
            newsTitleDes: '了解企业动向与行业最新消息',
            p600: '高算力 RISC-V 边缘计算处理器',
            v7_t1: '高算力低功耗音频 DSP IP',
            v9_t1: '64 位图像 / 视频 DSP IP',
            r_t1: '高效能实时运算 RISC-V 标量处理器',
            e_t1: '超低功耗 RISC-V 微控器',
            e_t2: '超低功耗场景',
            data_center: '存储',
            audio: '音频',
            image: '图像',
            MachineLearning: '机器学习',
            video: '视频',
            Industrial:'工控',
            network: '网络',
            communication: '通信',
            headTitle0: "从端到边缘：RISC-V 高算力处理器",
            headTitle1: "领航 RISC-V 边缘计算应用新时代",
            btn_text: '进一步了解',
            btn: '回看发布会',
            rivaiProductLine: '睿思产品线',
            topRightSlogan: "睿思芯科受邀参加第 59 届 DAC 会议",
            topRightInfo: "睿思芯科携产品矩阵亮相会场，并发布最新64位AI/视频 DSP IP — RiVAI V+",
            headTitle3: "2022 广州 ICCAD 展会 惊艳面世",
            productIntro: '从垂直领域到中高端应用场景，向行业提供最前沿技术和产品能力',
            productList: {
                industryApplication: '行业应用',
                industryApplicationDes: '高度灵活、深度定制的指令集与微架构，契合不同垂直领域的应用需求',
                f1: {
                    t1: '工业控制',
                    t2: '优异可靠的实时控制性能，适用于工控、网络等实时运算场景'
                },
                f2: {
                    t1: '通信领域',
                    t2: '基于RISC-V的高算力处理器，强力支撑5G等通信应用需求'
                },
                f3: {
                    t1: '数据中心',
                    t2: '可灵活配置的超高算力服务器处理器，覆盖从边缘到云端各层级的计算与控制需求'
                },
                f4: {
                    t1: '音频视频',
                    t2: '业界领先架构的音视频DSP，带来更高质量的用户体验'
                },
                f5: {
                    t1: '深度学习',
                    t2: '专项算力优化，支撑深度学习的海量数据并行运算'
                },
                btnDes: '进一步了解'
            },
            advantage: {
                title: '技术优势',
                t1: '提供基于 RISC-V 的 DSP',
                t2: '在垂直领域架构（DSA）的深度定制'
            },
            message: {
                title: '“每一行代码都是在中国，由中国工程师敲出来的。”',
                content: '创始人谭章熹博士拥有清华大学电子工程系学士和硕士学位，加州大学伯克利分校计算机科学硕士和博士学位，师从以研发精简指令集计算机授勋 2017 年度图灵奖的大卫·帕特森教授。',
                des: '创始人寄语'
            }
        }
    },
    bodyAbout: {
        bodyAbout: {
            investmentEndorsement: {
                title: '投资背书',
                info: '创立以来，睿思芯科获得顶级投资机构背书，客户涵盖国内多家知名企业'
            },
            company_f: "睿思",
            company_l: "芯科",
            slogan: "致力于实现高端 CPU 自主可控",
            buttonNames1: "公司简介",
            buttonNames2: "发展历程",
            buttonNames3: "管理团队",
            buttonNames4: "荣誉与奖项",
            buttonNames5: "企业价值观",
            buttonNames6: "新闻动态",
            buttonNames7: "联系我们",
            buttonNames8: "招聘职位",

            aboutus: "关于我们",
            aboutuscontent1: "睿思芯科成立于2018年底，是一家提供 RISC-V 高端核心处理器解决方案的公司，创始团队来自于加州大学伯克利分校 RISC-V 原创项目组。公司主要开发基于 RISC-V 的高算力处理器 IP 核，提供垂直领域（DSA）定制处理器设计服务以及定制芯片解决方案，满足从边缘计算到数据中心等各领域的高算力要求，客户涵盖国内外多家知名企业，开展 IP 授权和 SoC 定制化开发合作。",
            aboutuscontent2: "",
            aboutuscontent3: "自创立以来，睿思芯科获得多家顶级投资机构背书，截止2022年底已完成多轮融资，股东包括北极光创投、字节跳动、高瓴创投、翼朴资本、联想创投、百度风投、水木投资集团、真格基金等。",
            aboutuscontent4: "睿思芯科中国总部位于深圳，在成都等地设有多个办公室。",

            tanbosays: "创始人寄语",
            rewards: "荣誉与奖项",
            rewardslower: "持续精进、屡获殊荣的金牌团队",

            viewofvaluepre: "具备创新活力和内生动力的组织文化，是我们应对挑战与机遇的基石",
            viewofvalue: "企业价值观",
            viewofvaluelower: "我们鼓励知识共享、倡导自由交流、包容不同想法、允许开放争议、追求极致专业，让个体持续创新和全力以赴的潜能，汇聚成开源的力量。",

            activitypre: "企业动向与行业最新消息",
            activity: "新闻动态",

            contactus: "联系我们",
            shenzhen: "深圳",
            headquarter: "总部",
            shenzhenaddresspre: "广东省 深圳市",
            shenzhenaddresslast: "广东省深圳市前海深港合作区",
            chengdu: "成都",
            subcompany: "子公司",
            chengduaddresspre: "四川省 成都市",
            chengduaddresslast: "四川省成都市高新区",
            infocowork: "商务合作",
            inforecruit: "简历投递",
            infotel: "公司总机",

            recruitpre: "技术驱动创新，发展聚合人才",
            recruit: "加入我们",
            recruitslogan: "一起奔跑，改变世界",
            recruitlinkto: "探索你感兴趣的职位",
            recruitgrow: "助力成长",
            recruitgrowContent: "富有挑战和影响力的工作内容，最前沿的知识和技术，与顶尖芯片工程师共事，引领你发现更多可能",
            recruitlive: "关怀生活",
            recruitliveContent: "健全的员工社会保险和医疗保障、多样的健康关怀、丰富的团建活动，快乐工作、健康生活",
            recruitdev: "关注发展",
            recruitdevContent: "针对性的培训项目、具有竞争力的薪酬激励体系和伴随公司发展的晋升空间，全方位赋能个人发展",
            recruittollerance: "包容个性",
            recruittolleranceContent: "自由包容的文化氛围，高效开放的办公环境，实行弹性灵活的工作机制，效率为先、结果导向",
        },
        devProgress: {
            growthExp: "发展历程",
            growthExpLower: "顺应潮流，积极应变",

            process1: {
                name: "2023",
                items: [{
                        time: "3月",
                        content: "获川创投数千万元战略融资；荣获深圳市“专精特新企业”称号"
                    }
                ]
            },
            process2: {
                name: "2022",
                items: [{
                        time: "5月",
                        content: "荣获投中网“2021年度中国半导体与集成电路产业最佳投资案例TOP10”"
                    },
                    {
                        time: "6月",
                        content: "发布高算力RISC-V向量处理器"
                    },
                    {
                        time: "12月",
                        content: "发布边缘计算处理器 RiVAI P600；获得国家级高新技术企业认定"
                    }
                ]
            },
            process3: {
                name: "2021",
                items: [{
                        time: "1月",
                        content: "与全球半导体头部企业签署合同落地边缘端应用"
                    },
                    {
                        time: "9月",
                        content: "完成 A＋ 轮融资，字节跳动及高瓴创投领投，多个知名机构跟投；与全球领先通讯服务商签署合同，布局高端处理器"
                    },
                    {
                        time: "12月",
                        content: "获得2021年度深圳天使母基金天使项目优秀企业；获得钛媒体2021年度前沿科技创新企业；获得第二十三届中国国际高新技术成果交易会优秀产品奖"
                    }
                ]
            },
            process4: {
                name: "2020",
                items: [{
                        time: "7月",
                        content: "32位芯片流片成功"
                    },
                    {
                        time: "10月",
                        content: "获得第九届中国创新创业大赛全国总决赛优秀企业奖"
                    },
                    {
                        time: "12月",
                        content: "64位芯片流片成功"
                    }
                ]
            },
            process5: {
                name: "2019",
                items: [{
                        time: "1月",
                        content: "自研芯片获得由中国开放指令生态（RISC-V）联盟前沿芯片奖"
                    },
                    {
                        time: "3月",
                        content: "完成 A 轮融资，北极光、创新梦工场等知名机构跟投"
                    },
                    {
                        time: "7月",
                        content: "获得由雷锋网评选的最佳产品成长奖"
                    }
                ]
            },
            process6: {
                name: "2018",
                items: [{
                        time: "8月",
                        content: "在深圳成立"
                    },
                    {
                        time: "11月",
                        content: "推出基于RISC-V的64位可编程芯片"
                    }
                ]
            },
        },
        managerTeam: {
            tanboSays: "“每一行代码都是在中国，由中国工程师敲出来的。”",
            tanboInto: "创始人谭章熹博士拥有清华大学电子工程系学士和硕士学位，加州大学伯克利分校计算机科学硕士和博士学位，师从以研发精简指令集计算机授勋 2017 年度图灵奖的大卫·帕特森教授。",
            tanboMore: "了解更多",

            tanboMoreName: "谭章熹 博士",
            tanboMoreTitle: "创始人兼首席执行官",
            tanboMorePara1: "创始人谭章熹博士在伯克利师从2017年图灵奖获得者大卫·帕特森教授，进行计算机体系架构领域的研究。谭博士是帕特森教授唯一一位从事体系架构研究的中国大陆学生，并全程参与了 RISC-V 指令集标准的研发验证以及推广工作，现任 RISC-V 国际基金会董事。",
            tanboMorePara2_1: "谭博士2013年获博士学位后，作为创始工程师加入美国 Pure Storage 公司 (2015年在纽交所上市)，是其首位芯片设计工程师和关键产品FlashBlade",
            tanboMorePara2_2: "的最早主要设计工程师，该产品获得 2017 AIconics 硬件最佳发明奖。谭博士在硬件加速器和闪存上拥有几十项专利，是开源 SPARC 处理器（RAMP Gold）的发明人。2017年2月，谭博士于美国硅谷创立 OURS 公司，开展基于 RISC-V 的 CPU 开发工作。2018年8月，谭博士在深圳创立睿思芯科公司，致力于用最先进的 RISC-V 处理器技术和产品服务中国广大行业用户。",
        },
        riCarousel: {
            card1: {
                title: "创新",
                content: "打破定式 不设边界",
            },
            card2: {
                title: "进取",
                content: "勇于担当 追求极致",
            },
            card3: {
                title: "务实",
                content: "脚踏实地 实事求是",
            },
            card4: {
                title: "包容",
                content: "多元兼容 合作信任",
            }
        }
    },
    bodyDocumentation: {
        bodyDocumentation: {
            new_product_title: "RiVAI 2022年初新产品重磅登场",
            new_product_desc: "RiVAI-R1和RiVAI-V1两款新的基于RISC-V架构的DSP内核于2022年年初重磅登场；使用这些新内核，客户可以很方便地实现诸如实时运算（RC）、神经网络（NN）、人工智能/机器学习（AI/ML）等垂直领域（DSA）应用，也包括一些诸如物联网（IoT）边缘计算等面积极小化的、功率极度受限的应用。",
            article_t: "RiVAI-V1 DSP 内核赋能垂直领域应用（DSA）的深度定制",
            article_t2: "RiVAI-V1 Datasheet",
            article_c: "RiVAI-V1 DSP 内核是在 RISC-V 标量内核（支持 RV32IMAC）的基础上，增加了向量 Vector 扩展指令集，和部分 P 扩展指令，也可选配单精度浮点运…",
            article_c2: "RiVAI-V1是一款多发射、乱序执行、7级流水线的32位RISC-V处理器内核，是在具备高效实时处理功能的超标量处理器架构R1的基础上，加入了Vector指令集的高算力向量处理器；支持RISC-V的RV32IMFAC指令集和自定义的Vector扩展指令集，以及部分P指令。它是睿思芯科技术有限公司针对实时高算力控制和数据处理场景而开发的一款商用RISC-V处理器内核。主要应用于音频、视频和AI处理等垂直领域（DSA）",
        }
    },
    bodyProduct: {
        riP600: {
            headTitle1: "领航 RISC-V 高端应用新时代",
            headTitle2: "支持向量指令集，超宽向量宽度",
            headTitle2_2: "更强算力，满足机器学习等多样化计算需求",
            headTitle3: "卓越的能效表现",
            headTitle3_2: "轻松兼顾高算力与低功耗",
            headTitle4: "通用高算力，助力新型基建",
            headTitle4_2: "高算力 CPU 性能测试基准",
            title: "高算力 RISC-V 边缘计算处理器",
            funList1: "数据中心",
            funList2: "5G 通讯",
            funList3: "存储控制器",
            funList4: "机器学习",
            Cooperation: '合作咨询',
            performanceList1: '超标量 / 乱序 / 3发射 / 8级流水',
            performanceList2: '支持自定义指令集，可灵活扩展的客制化配置',
            performanceList3: '内存一致性设计 / TLB',
            performanceList4: '支持 IEEE 754 浮点算数标准',
            performanceList5: '支持主流深度学习框架',
            menuList1: '核心优势',
            menuList2: '技术特点',
            menuList3: '性能表现',
            menuList4: '生态体系',
            list1_t1: '支持向量指令集，完备的数据类型',
            list1_t2: '满足机器学习等多样化计算需求',
            list2_t1: '卓越的能效表现',
            list2_t2: '轻松兼顾高算力与低功耗',
            list3_t1: '广泛的市场应用',
            list3_t2: '覆盖从数据中心到移动计算等多个市场',
            introTitle: '通用高算力，助力新基建'
        },
        riE1: {
            title: "超低功耗 RISC-V 微控器",
            feature1: "高效的指令执行能力",
            feature2: "超低功耗，超长待机",
            feature3: "灵活的自定义指令集",
            efficiency_t: "高效的指令执行能力",
            efficiency_d: "满足超低功耗的轻应用场景，短流水线设计，可以高效地执行指令；同档次的处理器中性能更优。",
            power_t: "超低功耗，更高能效",
            power_d: "采用时钟门控技术、低功耗架构等多种超低功耗设计方法，定义了不同的休眠策略，使该处理器的功耗特别低，具有超高的能效比。表现在电池的续航能力上会更持久。",
            custom_t: "深度定制",
            custom_t2: "提供自定指令集和微架构解决方案",
            custom_d: "基于垂直领域深度定制架构 (Domain Specific Architecture) 设计理念，向客户提供一整套的 RISC-V 自定义指令集敏捷开发服务 助力客户的芯片达到极致优化的 PPA 性能。",
            buttonNames1: "高效的指令执行能力",
            buttonNames2: "超低功耗，更高能效",
            buttonNames3: "灵活的自定义指令集",
        },
        riR1: {
            title: "高效能实时运算 RISC-V CPU 内核",
            feature1: "优异的高可靠性实时计算",
            feature2: "杰出的性能和功耗",
            feature3: "灵活的自定义指令集",
            feature4: "支持多核异构",
            reliable_t: "适合工控、网络、控制等实时运算场景",
            reliable_features: "分支预测",
            reliable_features2: "多发射",
            reliable_features3: "乱序执行",
            reliable_features4: "七级流水线",
            reliable_features_d: "优异的实时控制性能，超标量架构运行效率",
            power_t: "杰出的性能和功耗",
            power_d: "性能更强，功耗却更低，超高能效比助力超强的续航能力",
            power_l_d: "芯片发热更低、可靠性更强、散热方案简单",
            power_r_d: "电池使用时间翻倍更耐久",
            custom_t: "深度定制",
            custom_t2: "提供自定指令集和微架构解决方案",
            custom_d: "基于垂直领域深度定制架构（Domain Specific Architecture）设计理念，向客户提供一整套的",
            custom_d2: "RISC-V 自定义指令集敏捷开发服务。助力客户的芯片达到极致优化的 PPA 性能。",
            multicore_t: "多核异构",
            multicore_d: "RiVAI-R 可以单独使用，也可配合 RiVAI-V 组成多核异构产品。",
            multicore_d2: "多核异构时，可以充分发挥不同内核的个体特性，提供强大的组合性能，以满足客户特定的应用场景需求。",
            safe_t: "可靠的 TEE 安全架构",
            safe_d: "基于 UC Berkeley 先进的 Keystone 技术，构建可信的执行环境 TEE，研发出了一整套完",
            safe_d2: "美配合 RiVAI CPU/DSP core 的、软硬件一体化的开放式安全框架，能提供比已有主流架",
            safe_d3: "构更好的可信安全防护。",
            buttonNames1: "优异的高可靠性实时计算",
            buttonNames2: "杰出的性能和功耗",
            buttonNames3: "灵活的自定义指令集",
            buttonNames4: "支持多核异构",
        },
        riV9: {
            title: "64 位视频/图像 DSP IP",
            buttonNames1: "核心优势 ",
            buttonNames2: "技术特点",
            slogan1: "全新增强 V 系列设计",
            slogan2: "应对视频、图像、机器学习领域的复杂计算挑战",
            slogan3: "业界领先架构，强悍计算性能保证",
            slogan4: "64 位 RISC-V 指令集架构",
            slogan5: "单芯片集成超标量单元与向量单元",
            slogan6: "专芯致智",
            slogan7: "针对机器学习/视频运算深度优化",
            slogan8: "支持矩阵运算",
            slogan9: "支持 Gather Load / Scatter Store",
            slogan10: "支持 AI 扩展指令",
            slogan11: "适应多样使用场景，精准契合应用需求",
            slogan12: "完备的数据类型",
            slogan13: "BF16 INT8/16/32/64 FP16/32",
            slogan14: "支持 IEEE 754 浮点算数标准",
            slogan15: "可灵活扩展的客制化配置",
            slogan16: "向量寄存器位宽 最高 2048 bit",
            slogan17: "运算宽度 最高 2048 bit",
            slogan18: "Load / Store 位宽 最高 512 bit",
            slogan19: "高算力低功耗音频 DSP IP",
            slogan20: "了解详情",
            list1: "支持矩阵指令",
            list2: "紧耦合向量单元",
            list3: "三发射 / 乱序 / 八级流水",
            list4: "支持 Linux 操作系统",
            list5: "提供自有 NatureDSP 兼容库、数学库、Eigen支持、NN库等",
            list6: "支持主流深度学习框架 (PyTorch 和 TensorFLow Lite)",

        },
        riV1: {
            n2_t2_i1: '灵活的并行运算单元',
            n2_t2_i2: '先进的超标量乱序架构',
            n2_t2_i3: '可自定义的指令集',
            n2_t2_i4: '可扩展的向量单元',
            n2_t3_t1: '超强能效，强劲续航',
            n2_t3_t2: '性能更强，而功耗却更低，超高能效比助力超强的续航能力',
            n2_t4: '滤波算法精度更高，对原始音频拟合更好，比竞品高 3dB，而人耳的感知幅值为 0.5~1 dB，这 3dB 差值可以显著提升对声音的听觉感受。',
            n4_t1: '成熟的 IDE 集成开发环境',
            n4_t2: '观看演示视频',
            n4_t2_t1: '使用教程',
            n4_t2_t2: '立即体验',
            n4_t3: '丰富的 SDK',
            n4_t4: 'DSP库、数学库、NN库…',
            n4_t5: '强大的编译器',
            title: "高算力低功耗音频 DSP IP",
            feature1: "领先的向量 DSP 架构",
            feature2: "超强的能效和强劲的电池续航",
            feature3: "灵活的自定义指令集",
            feature4: "完备的软硬件生态",
            frame_t: "全球首次将向量处理器用于音频 DSP 领域",
            frame_d: "DSP 处理器，从早期的 Motorola 和 TI 的 DSP 芯片，到现今先进手机芯片上的 DSP 处理器，经历了一个集成度越来越高，算力越来越大，功耗越来越低的发展历程。从处理器架构上来说，DSP经历了从第一代VLIW 架构到第二代向量处理器架构的进化。",
            frame_d2: "向量处理器架构的早期原型是 CRAY 超级计算机，高通的 Hexagon DSP 也采用向量处理器架构。睿思芯科是全球第一家将向量处理器应用于专业音频 DSP 领域的公司。",
            plan_t: "传统多 IP 供应商异构方案",
            plan_d: "多个 IP 增加芯片面积功耗开销； 多重软件系统增加开发复杂度； 多 IP 供应商重复投入。",
            solve_t: "单芯片全 RISC-V 解决方案",
            multitask_t: "全栈多任务多场景支持",
            multitask_d: "覆盖多种复杂算法，以及多种高采样率编码方案",
            power_t: "高算力、低功耗解决方案",
            performance: "性能对比",
            power_consump: "功耗对比",
            power_eff_t: "超强的能效和强劲的电池续航",
            power_eff_d: "性能更强，而功耗却更低，超高能效比助力超强的续航能力",
            power_eff_l_d: "芯片发热更低、可靠性更强、散热方案简单",
            power_eff_r_d: "电池使用时间翻倍更耐久",
            accuracy_t: "极高的运算精度",
            accuracy_t2: "支持高保真音质",
            accuracy_d: "专利的定点算法，能达到竞品开启浮点算法时的高精度，在相同采样率下提供更高的运算精度。这种高保真技术，使得声音的品质更加卓越。",
            flex_t: "灵活的自定义指令集",
            flex_d: "垂直领域深度定制，提供全套定义指令集和微架构服务",
            flex_example: "举例来说，蓝牙 BLE 5.2 的 LE Audio 需要支持 LC3。",
            flex_example2: "在某头部客户的音频产品案例中，睿思研发团队仅用 4 条自定义指令，以极低的硬件开销、大幅提升 LC3 音频算法的处理效率至原方案的 6 倍。",
            eco_t: "完备的生态体系",
            eco_d: "方便开发、移植和调试",
            mature_t: "成熟的量产品质保证",
            mature_d: "睿思芯科和国际知名头部客户紧密合作，已在客户产品中完成芯片集成与验证，并进入量产阶段，均在多个 Foundry 深亚微米先进工艺进行投片，完成了 IP 的硅验证，可为客户提供扎实的量产保证。",
            iconNames1: "音频编解码",
            iconNames2: "个性化 EQ",
            iconNames3: "AI 语音识别",
            iconNames4: "蓝牙基带",
            iconNames5: "360 环绕音效",
            iconNames6: "主动降噪",
            buttonNames1: "领先的向量 DSP 架构",
            buttonNames2: "超强的能效和强劲的电池续航",
            buttonNames3: "灵活的自定义指令集",
            buttonNames4: "完备的软硬件生态",
        }
    },
    recruitPage: {
        recruitList: {
            all: "全部",
            shenzheng: "深圳",
            chengdu: "成都"
        },
        recuitDetail: {
            apply: "立即申请",
            copy: "career@rivai.ai | 复制到剪贴板",
            copy_success: "复制成功",
            copy_failed: "复制失败",
        }
    }
}
export default zh;