//引入刚才的http.js文件
import https from './http.js';

let apiFun = {};

/* 获取列表 */
//查询列表，详情就是get
apiFun.getlist = function (params) {
    return https.get('/api/getlist', params)
}


apiFun.downloadFile = (params)=>{
    return https.getFile('/api/document/getfile', params)
}

apiFun.login = (params)=>{
    return https.post('/api/users/login', params)
}
apiFun.getUserInfo = (params)=>{
    return https.post('/api/users/getUserInfo', params)
}
apiFun.loadUserList = ()=>{
    return https.post('/api/users/getUserList')
}

apiFun.uploadFile = (params)=>{
    return https.upload('/api/news/upload',params)
}


apiFun.registIde = (params)=>{
    return https.post('/api/users/registIde', params)
}
apiFun.logOffIde = (params)=>{
    return https.post('/api/users/logOffIde', params)
}
apiFun.setManage = (params)=>{
    return https.post('/api/users/setManage', params)
}
apiFun.cancelManage = (params)=>{
    return https.post('/api/users/cancelManage', params)
}


apiFun.getIdeUrl = (params)=>{
    return https.post('/api/users/getIdeUrl', params)
}


apiFun.updateUserInfo = (params)=>{
    return https.post('/api/users/updateUserInfo', params)
}

apiFun.changePassword = (params)=>{
    return https.post('/api/users/changePassword', params)
}


apiFun.loadAllInfo = (params)=>{
    return https.post('/api/base/loadAllInfo', params)
}

apiFun.updateInfo = (params)=>{
    return https.post('/api/base/updateInfo', params)
}


apiFun.loadRecruitList = (params)=>{
    return https.post('/api/recruit/loadList', params)
}
apiFun.saveRecruitLinkExam = (params)=>{
    return https.post('/api/recruit/saveRecruitLinkExam', params)
}


apiFun.loadNewsList = (params)=>{
    return https.post('/api/news/loadList', params)
}

apiFun.addNews = (params)=>{
    return https.post('/api/news/add', params)
}
apiFun.loadNews = (params)=>{
    return https.post('/api/news/getDetail', params)
}

apiFun.saveNews = (params)=>{
    return https.post('/api/news/update', params)
}
apiFun.loadNews = (params)=>{
    return https.post('/api/news/getDetail', params)
}
apiFun.removeNews = (params)=>{
    return https.post('/api/news/delete', params)
}

apiFun.updateListOrder = (params)=>{
    return https.post('/api/news/updateListOrder', params)
}




apiFun.addRecruit = (params)=>{
    return https.post('/api/recruit/add', params)
}
apiFun.saveRecruit = (params)=>{
    return https.post('/api/recruit/update', params)
}
apiFun.removeRecruit = (params)=>{
    return https.post('/api/recruit/delete', params)
}

apiFun.resumeDilivery = (params)=>{
    return https.upload('/api/resume/resumeDilivery', params)
}

apiFun.getResumeList = (params)=>{
    return https.post('/api/resume/getResumeList', params)
}

apiFun.sendMatchMail = (params)=>{
    return https.post('/api/resume/sendMatchMail', params)
}

apiFun.consult = (params)=>{
    return https.post('/api/consult/sendMail', params)
}

apiFun.getCode = (params)=>{
    return https.post('/api/users/sendMailCode', params)
}
apiFun.register = (params)=>{
    return https.post('/api/users/register', params)
}
apiFun.loadRecruit = (params)=>{
    return https.post('/api/recruit/getDetail', params)
}

export default apiFun;