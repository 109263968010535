const en = {
    pageHeader: {
        pageHeader: {
            mobleHeader: {
                index: 'Home',
                product: 'Products',
                product_v: 'RiVAI V',
                product_v7: 'RiVAI V7',
                product_v9: 'RiVAI V9+',
                product_r: 'RiVAI R',
                product_p: 'RiVAI P',
                product_p600: 'RiVAI P600',
                product_e: 'RiVAI E',
                aboutRivai: 'About us',
                joinRivai: 'Careers',
                ideDoc: 'Studio'
            }
        }
    },
    pageFooter: {
        slogan: 'RiVAI provides microprocessor cores based on the open RISC-V technology targeting specifically the high-end and emerging markets.',
        copyRight: 'Copyright © 2018 - 2023 RiVAI Technologies (Shenzhen) Co., Ltd. All rights reserved.',
        number1: 'Guangdong ICP No. 18130670 -1',
        number2: 'YGPANB No. 44030502003396',
        btnList: {
            product: {
                title: 'Products'
            },
            aboutRivai: {
                title: 'Company',
                introduce: "About",
                value: "Values",
                devProgress: "History",
                team: "Founder",
                active: "Events",
                contact: "Contact us"
            },
            joinRivai: {
                title: 'Careers',
                recruit: 'Jobs'
            }
        }
    },
    bodyOverview: {
        bodyOverview: {
            more: 'more',
            joinUs: 'join us',
            aboutUs: 'About us',
            aboutUsDes: 'Founded at the end of 2018, RiVAI Technology delivers RISC-V high-end core processor solutions. The company has its origins in the UC Berkeley RISC-V project, with its founder and CEO coming from the original RISC-V team.',
            newsTitle: 'News',
            newsTitleDes: 'The latest news and information about RiVAI',
            p600: 'High Efficiency RISC-V Processor for Edge Computing',
            v7_t1: 'High efficiency low power DSP IP',
            v9_t1: '64 bit Image / Video DSP IP',
            r_t1: 'High-efficiency real-time scalar RISC-V CPU core',
            e_t1: 'Ultra-low power RISC-V microcontroller CPU core',
            e_t2: 'Ultra-low power consumption scenarios',
            data_center: 'Storage',
            audio: 'Audio',
            image: 'Image',
            MachineLearning: 'Machine Learning',
            video: 'Video',
            Industrial:'Industry',
            network: 'Network',
            communication: 'Communication',
            btn: 'Watch conference',
            rivaiProductLine: 'RiVAI Product Families',
            topRightSlogan: "RiVAI at DAC59",
            topRightInfo: "RiVAI Technologies unveiled its 64-Bit AI/Video DSP IP—Rivai V+ at the 59th Design Automation Conference",
            headTitle0: "From Device to Edge: High Efficiency RISC-V Processor",
            headTitle1: "The New Era of RISC-V in Edge Computing",
            headTitle2: "June 10-14, 2022 ",
            headTitle2_2: "Booth #2340",
            headTitle3: "ICCAD / Xiamen / 2022.12.26",
            productIntro: 'From domain-specific to high-performance applications, RiVAI microprocessors always offer the most advanced technologies and outstanding performance.',
            productList: {
                industryApplication: 'Industry application',
                // industryApplicationDes: 'Highly flexible and deeply customized instruction set and micro-architecture to meet the application requirements of different vertical fields',
                industryApplicationDes: '',
                f1: {
                    t1: 'Industrial Control System',
                    t2: ''
                },
                f2: {
                    t1: 'Communication',
                    t2: ''
                },
                f3: {
                    t1: 'Data Center',
                    t2: ''
                },
                f4: {
                    t1: 'Video & Audio',
                    t2: ''
                },
                f5: {
                    t1: 'Machine Learning',
                    t2: ''
                },
                btnDes: 'more'
            },
            advantage: {
                title: 'Technology advantages',
                t1: 'RISC-V vector based DSP',
                t2: 'Deep customized DSA solution'
            },
            message: {
                title: '',
                content: 'Dr. Zhangxi Tan is a graduate of Tsinghua University and holds a Ph.D. from the University of California, Berkeley, specializing in computer architecture and VLSI.  He is a student of Prof. David Patterson, the creator of RISC and the winner of the 2017 Turing Award.  Dr. Tan is Prof. Patterson’s first and only Ph.D. student from mainland China.',
                des: 'Founder'
            }
        }
    },
    bodyAbout: {
        bodyAbout: {
            investmentEndorsement: {
                title: 'Investors',
                info: 'Since its incorporation, RiVAI has been highly valued and backed from prestigious financial and industry investors'
            },
            company_f: "RiV",
            company_l: "AI",
            slogan: "",
            buttonNames1: "About us",
            buttonNames2: "Development path",
            buttonNames3: "Management team",
            buttonNames4: "Honors and Awards",
            buttonNames5: "Values",
            buttonNames6: "Events",
            buttonNames7: "Contact us",
            buttonNames8: "Careers",

            aboutus: "About us",
            aboutuscontent1: "Founded at the end of 2018, RiVAI Technology delivers RISC-V high-end core processor solutions. The company has its origins in the UC Berkeley RISC-V project, with its founder and CEO coming from the original RISC-V team. The company develops high-efficiency processor IP cores based on RISC-V designs, custom processor (DSA) design services, and custom chip solutions, enabling customers to meet the high computing power requirements in applications ranging from edge to data center. Its customers include many well-known domestic and foreign companies. RiVAI and its customers work cooperatively through IP authorization and customized SoC development.",
            aboutuscontent2: "",
            aboutuscontent3: "Since its establishment, RiVAI has been endorsed by top investment institutions, and has completed multiple rounds of financing by the end of 2022. Shareholders include Northern Lights Ventures, ByteDance, Hillhouse Ventures, Kington Capital, Lenovo Ventures, Baidu Ventures, Waterwood and ZhenFund, etc.",
            aboutuscontent4: "RiVAI is backed by leading financial and industry investors, and has offices in Shenzhen (China), Chengdu (China).",

            tanbosays: "Founder",
            rewards: "Honors and Awards",
            rewardslower: "Continuous improvement, award-winning gold team",

            viewofvaluepre: "Our culture that features a vitality of innovation prepared us to handle challenges and seize opportunities",
            viewofvalue: "Values",
            viewofvaluelower: "",

            activitypre: "Corporate trends and industry latest news",
            activity: "Events",

            contactus: "Contact us",
            shenzhen: "Shenzhen",
            headquarter: "Headquarters",
            shenzhenaddresspre: "Shenzhen City, Guangdong Province",
            shenzhenaddresslast: "Qianhai Shenzhen-Hong Kong Cooperation Zone, Shenzhen, Guangdong Province",
            chengdu: "Chengdu",
            subcompany: "Subsidiary",
            chengduaddresspre: "Chengdu, Sichuan Province",
            chengduaddresslast: "Chengdu High-tech Zone, Sichuan Province",
            infocowork: "Business Cooperation",
            inforecruit: "Resume delivery",
            infotel: "Tel",
            recruitpre: "Technology drives innovation, develops and aggregates talents",
            recruit: "Join RiVAI",
            recruitslogan: "Together, and change the world",
            recruitlinkto: "Find your perfect role.",
            recruitgrow: "Grow into",
            recruitgrowContent: "Challenging and influential work content, cutting-edge knowledge and technology, working with top chip engineers, leading you to discover more possibilities.",
            recruitlive: "RiVAI life",
            recruitliveContent: "Sound employee social insurance and medical security, diverse health care, rich team building activities, happy work and healthy life.",
            recruitdev: "Development",
            recruitdevContent: "Targeted training programs, competitive salary incentive system and promotion space accompanying the company's development, empowering personal development in an all-round way.",
            recruittollerance: "Inclusion",
            recruittolleranceContent: "Free and inclusive cultural atmosphere, efficient and open office environment, flexible and flexible working mechanism, efficiency first, result orientation.",
        },
        devProgress: {
            growthExp: "发展历程",
            growthExpLower: "顺应潮流，积极应变",

            process1: {
                name: "2023",
                items: [{
                        time: "Mar",
                        content: "获川创投数千万元战略融资；荣获深圳市“专精特新企业”称号"
                    }
                ]
            },
            process2: {
                name: "2022",
                items: [{
                        time: "May",
                        content: "荣获投中网“2021年度中国半导体与集成电路产业最佳投资案例TOP10”"
                    },
                    {
                        time: "Jun",
                        content: "发布高算力RISC-V向量处理器"
                    },
                    {
                        time: "Dec",
                        content: "发布边缘计算处理器 RiVAI P600；获得国家级高新技术企业认定"
                    }
                ]
            },
            process3: {
                name: "2021",
                items: [{
                        time: "Jan",
                        content: "Established strategic partnerships with top global semiconductor companies to implement commercialized edge applications"
                    },
                    {
                        time: "Sep",
                        content: "Completed series A+ financing, led by ByteDance and Hillhouse Ventures, followed by other internationally-known institutions; established strategic partnerships with world's leading communication service providers for planning for high-end processors."
                    },
                    {
                        time: "Dec",
                        content: "Established strategic partnerships with Lenovo in cloud notebook."
                    }
                ]
            },
            process4: {
                name: "2020",
                items: [{
                        time: "Jul",
                        content: "Successfully tape out 32-bit chip."
                    },
                    {
                        time: "Oct",
                        content: "Won the excellence award of the enterprise group in the electronic information industry final of the 12th China Shenzhen Innovation and Entrepreneurship Competition."
                    },
                    {
                        time: "Dec",
                        content: "Successfully tape out 64-bit chip."
                    }
                ]
            },
            process5: {
                name: "2019",
                items: [{
                        time: "Jan",
                        content: "RiVAI's chip received award of advanced chip by China RISC-V Alliance (CRVIC)."
                    },
                    {
                        time: "Mar",
                        content: "Completed series A financing, followed by Northern Lights and Innovation DreamWorks."
                    },
                    {
                        time: "Jul",
                        content: "Awarded best product development by Leiphone."
                    }
                ]
            },
            process6: {
                name: "2018",
                items: [{
                        time: "Aug",
                        content: "Founded in Shenzhen."
                    },
                    {
                        time: "Nov",
                        content: "Released risc-v-based 64-bit programmable chip."
                    }
                ]
            },
        },
        managerTeam: {
            tanboSays: 'Dr. Zhangxi Tan',
            tanboInto: "Dr. Zhangxi Tan is a graduate of Tsinghua University and holds a Ph.D. from the University of California, Berkeley, specializing in computer architecture and VLSI.  He is a student of Prof. David Patterson, the creator of RISC and the winner of the 2017 Turing Award.  Dr. Tan is Prof. Patterson’s first and only Ph.D. student from mainland China.",
            tanboMore: "more",

            tanboMoreName: "Dr. Zhangxi Tan",
            tanboMoreTitle: "Founder and CEO",
            tanboMorePara1: "Supervised by Prof. David Patterson, winner of the 2017 Turing Award, Dr. Zhangxi Tan received his Ph.D degree from UC Berkeley, specializing in computer architecture and VLSI design. He is Dr. Patterson’s first and only Ph.D student from mainland China. Dr. Tan has actively participated in the creation, development, verification, and promotion of RISC-V along with the rest of the original developing lab members under Dr. Patterson’s leadership. He is now a board member of the RISC-V International. ",
            tanboMorePara2_1: "After receiving his Ph.D. degree in 2013, Dr. Tan joined Pure Storage Corporation (listed on the New York Stock Exchange in 2015) as Founding Engineer. He was the company’s first chip design engineer and the principal design engineer of one of the company’s feature product",
            tanboMorePara2_2: ". The product was awarded the Best Innovation in Hardware of the Alconics 2017. Dr. Tan holds over dozens of patents on hardware accelerators and flash memory, and invented the open-source SPARC processor (RAMP Gold). In February 2017, Dr. Tan founded OURS in Silicon Valley, USA, a company that developed RISC-V-based CPU. In August 2018, Dr. Tan founded RiVAI in Shenzhen China, dedicated to serving clients in China with the most advanced RISC-V processor technology and products."
        },
        riCarousel: {
            card1: {
                title: "Innovation",
                content: "Break the limits, unleash the impossibilities",
            },
            card2: {
                title: "Motivation",
                content: "Take on challenges, strive for perfection",
            },
            card3: {
                title: "Integrity",
                content: "Under promise, over delivery",
            },
            card4: {
                title: "Diversity",
                content: "Embrace the differences, collaborate with trust",
            }
        }
    },
    bodyDocumentation: {
        bodyDocumentation: {
            new_product_title: "RiVAI 2022年初新产品重磅登场",
            new_product_desc: "RiVAI-R1和RiVAI-V1两款新的基于RISC-V架构的DSP内核于2022年年初重磅登场；使用这些新内核，客户可以很方便地实现诸如实时运算（RC）、神经网络（NN）、人工智能/机器学习（AI/ML）等垂直领域（DSA）应用，也包括一些诸如物联网（IoT）边缘计算等面积极小化的、功率极度受限的应用。",
            article_t: "RiVAI-V1 DSP 内核赋能垂直领域应用（DSA）的深度定制",
            article_t2: "RiVAI-V1 Datasheet",
            article_c: "RiVAI-V1 DSP 内核是在 RISC-V 标量内核（支持 RV32IMAC）的基础上，增加了向量 Vector 扩展指令集，和部分 P 扩展指令，也可选配单精度浮点运…",
            article_c2: "RiVAI-V1是一款多发射、乱序执行、7级流水线的32位RISC-V处理器内核，是在具备高效实时处理功能的超标量处理器架构R1的基础上，加入了Vector指令集的高算力向量处理器；支持RISC-V的RV32IMFAC指令集和自定义的Vector扩展指令集，以及部分P指令。它是睿思芯科技术有限公司针对实时高算力控制和数据处理场景而开发的一款商用RISC-V处理器内核。主要应用于音频、视频和AI处理等垂直领域（DSA）",
        }
    },
    bodyProduct: {
        riP600: {
            headTitle1: "The New Era of RISC-V in High End Application",
            headTitle2: "Vector Instruction Set Support，Extended Vector Length",
            headTitle2_2: "Enhanced vector unit design optimized for machine learning workloads",
            headTitle3: "Superior Energy Efficiency",
            headTitle3_2: "Better performance, lower power consumption",
            headTitle4: "Empower the New Infrastructure Construction",
            headTitle4_2: "High efficiency CPU performance test benchmark",
            performanceList1: 'Superscalar / Out-of-order Execution / 3-issue / 8-stage Pipeline',
            performanceList2: 'High level of configurablity and design scalability',
            performanceList3: 'Memory Consistency / TLB',
            performanceList4: ' IEEE 754 Support',
            performanceList5: 'Mainstream Deep Learning Frameworks Support',
            menuList1: 'Key Benefits',
            menuList2: 'Features',
            menuList3: 'Performance',
            menuList4: 'Ecosystem',
            title: "High Efficiency RISC-V Processor for Edge Computing",
            funList1: "Data Center",
            funList2: "5G Networks",
            funList3: "Storage Controller",
            funList4: "Machine Learning",
            Cooperation: 'Contact Us',
            list1_t1: 'Mixed precision data type and vector instruction set support',
            list1_t2: 'multi-scenario feature-rich data path',
            list2_t1: 'Superb power efficiency',
            list2_t2: 'high efficiency with low power',
            list3_t1: 'Wide range of applications',
            list3_t2: 'from data center to mobile computing',
            introTitle: 'Empower the new infrastructure construction'
        },
        riE1: {
            title: "Ultra-low power RISC-V microcontroller CPU core",
            feature1: "High efficient instruction execution pipeline",
            feature2: "Ultra-low power, High energy efficiency",
            feature3: "flexible custom instructions",
            efficiency_t: "High efficient instruction execution pipeline",
            efficiency_d: "Short pipeline design for low-power applications; class leading performance.",
            power_t: "Ultra-low power, high energy efficiency",
            power_d: "Extensive clock gating and low-power microarchitecture designs, multiple sleep modes, and data gating.",
            custom_t: "Deep customized solutions",
            custom_t2: "Customized instruction set and microarchitecture",
            custom_d: "With domain specific architecture, we optimize customer silicon PPA with agile development of RISC-V custom instruction set.",
            buttonNames1: "High efficient instruction execution pipeline",
            buttonNames2: "Ultra-low power, high energy efficiency",
            buttonNames3: "flexible custom instructions",
        },
        riR1: {
            title: "High-efficiency real-time scalar RISC-V CPU core",
            feature1: "Reliable and real-time computing system",
            feature2: "Superior performance and power efficiency",
            feature3: "flexible custom instructions",
            feature4: "Support heterogeneous multicore integration",
            reliable_t: "Excellent for real-time computing applications,such as industrial control systems, local area network, and etc.",
            reliable_features: "Branch prediction",
            reliable_features2: "Multi-issue",
            reliable_features3: "Out-of-order execution",
            reliable_features4: "7-stage pipeline",
            reliable_features_d: "Outstanding real-time sequential performance, high-efficiency superscalar architecture.",
            power_t: "Outstanding performance and power efficiency",
            power_d: "Better performance and power efficiency. Great energy efficiency ratio and longer battery life.",
            power_l_d: "Lower chip heating, stronger reliability, and simple cooling solution.",
            power_r_d: "Double the battery life and last longer.",
            custom_t: "Deep customized solutions",
            custom_t2: "Customized instruction set and microarchitecture",
            custom_d: "With domain specific architecture, we optimize customer silicon PPA",
            custom_d2: "with agile development of RISC-V custom instruction set.",
            multicore_t: "Heterogeneous multicore architecture",
            multicore_d: "R core can work standalone or integrate with several RiVAI-V cores in heterogeneous multicore products.",
            multicore_d2: "Heterogeneous multicore architecture unleashes energy efficiency of each individual core and combines together to provide computing power needed under different scenarios.",
            safe_t: "Highly reliable TEE security architecture",
            safe_d: "Our TEE support roots from the UC Berkeley Keystone open-source security project. A complete set of open",
            safe_d2: "HW/SW integrated security architecture that works with RiVAI-DSP/CPU cores, providing stronger security",
            safe_d3: "than existing mainstream architectures.",
            buttonNames1: "Reliable and real-time computing system",
            buttonNames2: "Superior performance and power efficiency",
            buttonNames3: "flexible custom instructions",
            buttonNames4: "Support heterogeneous multicore integration",
        },
        riV9: {
            title: "64 bit Video / Image DSP IP",
            buttonNames1: "Key Benifits ",
            buttonNames2: "Features",
            slogan1: "Enhanced V-Series design",
            slogan2: "Optimized for machine learning, video and imaging workloads",
            slogan3: "World's leading vector DSP architecture",
            slogan4: "64-bit RISC-V ISA",
            slogan5: "Tightly-coupled superscalar and vector unit in a single chip",
            slogan6: "",
            slogan7: "Optimized design for Machine-Learning/Video computing",
            slogan8: "Matrix operation support",
            slogan9: "Gather load / scatter store support,",
            slogan10: "AI extension instruction support",
            slogan11: "Multi-Scenario Feature-rich Data path",
            slogan12: "Mixed precision data type support",
            slogan13: "BF16 INT8/16/32/64 FP16/32",
            slogan14: "IEEE 754 vector floating-point",
            slogan15: "Flexible parameterized configuration",
            slogan16: "VLEN up to 2048 bit",
            slogan17: "ALU up to 2048 bit",
            slogan18: "Load/Store up to 512 bit",
            slogan19: "High efficiency low power DSP IP",
            slogan20: "Learn more",
            list1: "Custom Matrix instruction support",
            list2: "Tightly-coupled vector unit",
            list3: "3-issue / Out-of-order / 8-stage scalar pipeline",
            list4: "Full Linux OS support",
            list5: "NatureDSP-compatible library, math library, Eigen and NN library support",
            list6: "Main stream deep learning frameworks support (PyTorch and TensorFLow Lite)",

        },
        riV1: {
            n2_t2_i1: 'Flexible parallel computing unit',
            n2_t2_i2: 'Advanced superscalar out-of-order architecture',
            n2_t2_i3: 'Customizable instruction set',
            n2_t2_i4: 'Scalable Vector Unit',
            n2_t3_t1: 'Super energy efficiency, strong battery life',
            n2_t3_t2: 'More performance, but lower power consumption, ultra-high energy efficiency ratio helps super battery life',
            n2_t4: 'The filtering algorithm has higher precision and better fitting of the original audio, which is 3dB higher than that of competing products, and the perception amplitude of the human ear is 0.5~1 dB. This 3dB difference can significantly improve the auditory experience of sound.',
            n4_t1: 'Mature IDE Integrated Development Environment',
            n4_t2: 'Watch the demo video',
            n4_t2_t1: 'Tutorials',
            n4_t2_t2: 'Get started now',
            n4_t3: 'Rich SDK',
            n4_t4: 'DSP library, math library, NN library…',
            n4_t5: 'Powerful compiler',
            title: "A revolutionary vector processor",
            feature1: "World's leading vector DSP architecture",
            feature2: "Superb performance and long-lasting battery life",
            feature3: "flexible custom instructions",
            feature4: "Complete software and hardware ecosystems",
            frame_t: "World's first audio DSP IP with vector processor architecture",
            frame_d: "From early Motorola and TI DSPs to modern smartphone DSPs in application processors, DSPs have been designed and optimized for increasingly higher degree of integration, computing power, and power efficiency. In terms of the processor architecture, DSP went from the first-generation VLIW architecture to the second-generation vector processor architecture. ",
            frame_d2: "The vector processor design was first seen on the Cray-1 supercomputer. It is now implemented in the Hexagon DSP in Qualcomm's latest smartphones APs. RiVAI is the world's first to apply the vector processor architecture in professional audio DSPs.",
            plan_t: "Traditional heterogeneous solutions with multiple IP vendors",
            plan_d: "Having multiple IPs increases power consumption; Multiple ISAs increases software development complexities; buying from multiple IP vendors increases costs.",
            solve_t: "All-in-one RISC-V single-chip solution",
            multitask_t: "Multi-task and multi-scene full-stack support",
            multitask_d: "Provides a rich library of complex algorithms, and high-bandwidth encoding/decoding schemes",
            power_t: "Low power solution for high efficiency computing",
            performance: "Performance comparison",
            power_consump: "Power consumption comparison",
            power_eff_t: "Superb energy efficiency and extended battery life",
            power_eff_d: "More performance but lower power consumption; Great energy efficiency and longer battery life.",
            power_eff_l_d: "Lower heat, better silicon reliability, and easy cooling solution",
            power_eff_r_d: "Superior battery life",
            accuracy_t: "Extremely high computing precision",
            accuracy_t2: "Professional-grade high-fidelity sound quality",
            accuracy_d: "Our patented fixed-point data path architecture achieves top competitor's precision level without using floating-point, at the same time, it provides higher computation accuracy at the same sampling rate. This high-fidelity technology enhances sound quality.",
            flex_t: "Agile custom instructions",
            flex_d: "Turn-key service on adding custom instructions and microarchitecture optimizations.",
            flex_example: "Bluetooth 5.2 needs to support the LC3 codec.",
            flex_example2: "In our solution for an industry leading audio codec company, we increased the processing efficiency of the LC3 audio algorithm to 6 times by adding only 4 custom instructions at extremely low extra hardware cost.",
            eco_t: "Complete software and hardware ecosystems",
            eco_d: "Streamlined process of developing, porting and testing customer's code.",
            mature_t: "Mass-production ready quality",
            mature_d: "RiVAI has been working with numerous internationally renowned customers. Our IP integration and verification have been validated with our customers' silicon chips. Our IPs have been silicon-proven in a number of foundries with advanced deep submicron production processes. ",
            iconNames1: "Audio codec",
            iconNames2: "Personalized EQ",
            iconNames3: "Voice recognition",
            iconNames4: "Bluetooth baseband",
            iconNames5: "360-degree surround sound",
            iconNames6: "Active noise cancellation",
            buttonNames1: "World's leading vector DSP architecture",
            buttonNames2: "Superb performance and long-lasting battery life",
            buttonNames3: "flexible custom instructions",
            buttonNames4: "Complete software and hardware ecosystems",
        }
    },
    recruitPage: {
        recruitList: {
            all: "All",
            shenzheng: "Shenzhen",
            chengdu: "Chengdu"
        },
        recuitDetail: {
            apply: "Apply now",
            copy: "career@rivai.ai | Copy to clipboard",
            copy_success: "Copy successfully",
            copy_failed: "Copy failed",
        }
    }
}
export default en;