

let countDown = (config) => {
    let next = config.next
    let secondsToGo = config.expire;
    let timer
    const modal = window.antd.Modal.confirm({
      title: '消息',
      content: config.content,
      onCancel: () => {
        window.clearTimeout(timer) // 
      },
      onOk: () => {
        window.clearTimeout(timer)
        if (next) {
          next();
        }
      },
      cancelText: '稍后登录',
      okText: '去登录'
    });
    timer = setTimeout(() => {
      if (next) {
        next();
      }
      modal.destroy();
    }, secondsToGo * 1000);
  }

  export default countDown