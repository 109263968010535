import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'

import apiFun from "@/api/api.js";
import less from 'less'
import VueClipboard from 'vue-clipboard2'
import i18n from '@/utils/vueIN.js'
import VueLazyLoad from 'vue-lazyload'
import 'progressive-image/dist/index.css'
import progressive from 'progressive-image/dist/vue'; // 渐进式
Vue.use(progressive, {
    removePreview: true,
    scale: true
})

Vue.use(window.antd)
Vue.use(VueLazyLoad)
Vue.use(VueClipboard)
Vue.use(less)
Vue.prototype.$apiFun = apiFun; //请求接口api

Vue.config.productionTip = false

Vue.prototype.globalLinkTo = (url) => {
  window.open(url, '_self');
}

Vue.prototype.$countDown = (mes, seconds, next) => {
  let secondsToGo = seconds;
  const modal = window.antd.message.info({
    title: '消息',
    content: mes,
    onClose: () => {
      if (next) {
        next();
      }
    }
  });
  setTimeout(() => {
    modal.destroy();
  }, secondsToGo * 1000);
}

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')

export default Vue;