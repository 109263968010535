export default {
    getToken:()=>{
        let value = window.localStorage.getItem('token')
        console.log('token',value)
        return value
    },
    setToken:(value)=>{
        window.localStorage.setItem('token',value)
    },
    clearToken:()=>{
        window.localStorage.removeItem('token')
    }
}