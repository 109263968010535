import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

const overview = () => import('@/components/bodyOverview/bodyOverview.vue')
const product = () => import('@/components/bodyProduct/bodyProduct.vue')
const solution = () => import('@/components/bodySolution/bodySolution.vue')
const documentation = () => import('@/components/bodyDocumentation/bodyDocumentation.vue')
const about = () => import('@/components/bodyAbout/bodyAbout.vue')
const admin = () => import('@/components/adminPage/adminPage.vue')
const infoEdit = () => import('@/components/adminPage/infoEdit.vue')
const acitiveManage = () => import('@/components/adminPage/acitiveManage.vue')
const recruitManage = () => import('@/components/adminPage/recruitManage.vue')
const userManage = () => import('@/components/adminPage/userManage.vue')
const recuitList = () => import('@/components/recruitPage/recuitList.vue')
const recruitDetail = () => import('@/components/recruitPage/recruitDetail.vue')
const resumeDilivery = () => import('@/components/recruitPage/resumeDilivery.vue')
const newsDetail = () => import('@/components/bodyOverview/newsDetail.vue')
const riV7 = () => import('@/components/bodyProduct/riV7.vue')
const riV9 = () => import('@/components/bodyProduct/riV9.vue')
const riR1 = () => import('@/components/bodyProduct/riR1.vue')
const riE1 = () => import('@/components/bodyProduct/riE1.vue')
const riP600 = () => import('@/components/bodyProduct/riP600.vue')



const index = () => import('@/components/index.vue')
const newsManage = () => import('@/components/adminPage/newsManage.vue')
const baseInfo = () => import('@/components/adminPage/baseInfo.vue')
const resumeList = () => import('@/components/adminPage/resumeList.vue')
const recruitLinkExam = () => import('@/components/adminPage/recruitLinkExam.vue')
const ide = () => import('@/components/idePage/idePage.vue')
const NotFound = () => import('@/components/common/NotFound.vue')
const privatePage = () => import('@/components/recruitPage/privatePage.vue')

const routes = [{
    path: '/',
    redirect: '/index',
}, {
    name: 'index',
    path: '/index',
    component: index,
    redirect: '/overview',
    //  写入子模块
    children: [{
        name: 'NotFound',
        path: '/NotFound',
        component: NotFound
    }, {
        name: 'overview',
        path: '/overview',
        component: overview
    }, {
        name: 'doc-ide',
        path: '/doc-ide',
        component: ide
    }, {
        name: 'product',
        path: '/product',
        component: product,
        redirect: '/product/p',
        //  写入子模块
        children: [{
            path: '/product/v',
            redirect: '/product/v/v7',
        }, {
            path: '/product/v/v7',
            component: riV7,
        }, {
            path: '/product/v/v9',
            component: riV9,
        }, {
            path: '/product/r',
            component: riR1,
        }, {
            path: '/product/e',
            component: riE1,
        }, {
            path: '/product/p',
            redirect: '/product/p/p600',
        }, 
        {
            path: '/product/p/p600',
            component: riP600,
        }]
    }, {
        name: 'solution',
        path: '/solution',
        component: solution
    }, {
        name: 'documentation',
        path: '/documentation',
        component: documentation
    }, {
        name: 'about',
        path: '/about',
        component: about
    }, {
        name: 'recuitList',
        path: '/recuitList',
        component: recuitList
    }, {
        name: 'resumeDilivery',
        path: '/resumeDilivery',
        component: resumeDilivery
    }, {
        name: 'recruitDetail',
        path: '/recruitDetail',
        component: recruitDetail
    }, {
        name: 'newsDetail',
        path: '/newsDetail',
        component: newsDetail
    }]
}, {
    name: 'private',
    path: '/private',
    component: privatePage
},{
    name: 'admin',
    path: '/admin',
    component: admin,

    //  写入子模块
    children: [{
        path: '/admin/infoEdit',
        component: infoEdit,
    }, {
        path: '/admin/recruitManage',
        component: recruitManage,
    }, {
        path: '/admin/userManage',
        component: userManage,
    }, {
        path: '/admin/newsManage',
        component: newsManage,
    }, {
        path: '/admin/acitiveManage',
        component: acitiveManage,
    }, {
        path: '/admin/baseInfo',
        component: baseInfo,
    }, {
        path: '/admin/resumeList',
        component: resumeList,
    }, {
        path: '/admin/recruitLinkExam',
        component: recruitLinkExam,
    }]
}, {
    path: '*',
    redirect: '/NotFound'
}]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        console.log(to, from, savedPosition)
        if (to.hash) {
            return {
                selector: to.hash
            }
        } else {
            window.scrollTo(0, 0);
        }
    }
})

function getRoutesList(routes,pre) {
    return routes.reduce((array, route) => {
      const path = `${pre}${route.path}`;
  
      if (route.path !== '*' && !route.path.includes('admin')) {
        array.push(path);
      }
  
      if (route.children) {
        array.push(...getRoutesList(route.children,pre));
      }
  
      return array;
    }, []);
  }
  
  
  // getRoutesList(router.options.routes, 'https://zigamiklic.com');
  function getRoutesXML() {
    const list = getRoutesList(router.options.routes, 'https://rivai-ic.com.cn')
      .map(route => `<url><loc>${route}</loc></url>`)
      .join('\r\n');
    return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
      ${list}
    </urlset>`;
  }
  getRoutesXML
// console.log(getRoutesXML())

export default router