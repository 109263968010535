// 创建文件vueIN.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
// 引入各个语言配置文件
import zh from './zh';
import en from './en';
// 创建vue-i18n实例i18n

let getURLParams = (url) => {
    let param = url.split('#')[0]; //获取#/之前的字符串
    let paramContent = param.split('?')[1]; //获取?之后的参数字符串
    if (paramContent === undefined) {
        return ''
    }
    let paramsArray = paramContent.split('&'); //参数字符串分割为数组
    let paramResult = {};
    //遍历数组，拿到json对象
    paramsArray.forEach((item, index, paramsArray) => {
        paramResult[paramsArray[index].split('=')[0]] = paramsArray[index].split('=')[1];
    })
    return paramResult;
}


let urlDel = (name) => {
    let url = window.location;
    let baseUrl = url.origin + url.pathname + "?";
    let query = url.search.substr(1);
    if (query.indexOf(name) > -1) {
        let obj = {}
        let arr = query.split("&");
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].split("=");
            obj[arr[i][0]] = arr[i][1];
        }
        delete obj[name];
        url = baseUrl + JSON.stringify(obj).replace(/[\\"\\{\\}]/g, "").replace(/\\:/g, "=").replace(/\\,/g, "&");
        return url
    } else {
        return window.location.href;
    }
}


let lang = 'zh'


let params = getURLParams(window.location.href)
if (params.lang) {
    lang = params.lang
    window.localStorage.setItem('lang', lang)
    let newUrl = urlDel('lang')
    console.log(newUrl)
    history.replaceState('', '', newUrl)
} else {
    if (window.localStorage.getItem('lang')) {
        lang = window.localStorage.getItem('lang')
    }
}




lang = lang ? lang : 'zh'
const i18n = new VueI18n({
    // 设置默认语言
    locale: lang, // 语言标识
    // 添加多语言（每一个语言标示对应一个语言文件）
    messages: {
        zh,
        en,
    }
})
// 暴露i18n
export default i18n;