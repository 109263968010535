<template>
  <div>
    <router-view></router-view>
    <a-back-top />
  </div>
</template>

<script>

export default {
  name: "App",
  components: {
  },
  mounted() {
    // 关闭loading
    document.getElementById('mainLoading').style.display = 'none';
    console.log(this.$t("language.zh"))
  },
  methods: {

  }
};
</script>

<style>
/* html {
  filter: grayscale(100%);
} */

.ant-carousel .slick-dots li.slick-active button {
  background: #aeaeae !important;
  height: 10px !important;
  border-radius: 5px !important;
}

.ant-carousel .slick-dots li button {
  background: #aeaeae !important;
  height: 10px !important;
  border-radius: 5px !important;
}
</style>
<style lang="less" src="./app.less">

</style>

<style lang="less" src="./app_m.less">

</style>
